import React from 'react';

import { BasePicker, Text } from '@components';
import { Box, Show } from '@core';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';
import { CURRENCIES } from '@shared/constants/currencies';
import { usePlansParametersAndActionsContext } from '@context';

const currencies = Object.keys(CURRENCIES).map((currency) => ({
  value: currency,
  displayValue: currency,
}));

const CurrencyPicker = ({ titleDesktop, titleMobile }) => {
  const { plansParameters, actions } = usePlansParametersAndActionsContext();

  const currencyPickerProps = {
    options: currencies,
    selected: currencies.find(({ value }) => value === plansParameters.currencyCode),
    onCurrencyChange: (currency) => {
      actions.getByCurrency(currency);
      trackCustomGA4Event({
        eventName: GA4_EVENTS.currencyChange,
        params: { currency },
      });
    },
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      position="relative"
      width="fit-content"
      columnGap={{ md: 8 }}
    >
      {!(currencyPickerProps.options && currencyPickerProps.selected) && (
        <Box position="absolute" top={0} left={0} width="100%">
          <Text.Body1 isLoading />
        </Box>
      )}
      {titleDesktop && (
        <Show.TabletLargeAndUp>
          <Text.Body2 as="span">{titleDesktop}</Text.Body2>
        </Show.TabletLargeAndUp>
      )}
      {titleMobile && (
        <Show.TabletSmall>
          <Text.Body2 as="span">{titleMobile}</Text.Body2>
        </Show.TabletSmall>
      )}
      <Box
        width="fit-content"
        position="relative"
        minWidth={{ _: 87, md: 55 }}
        height={32}
        display="flex"
        alignItems="center"
      >
        {currencyPickerProps.options && currencyPickerProps.selected && (
          <BasePicker
            selected={currencyPickerProps.selected}
            options={currencyPickerProps.options}
            dataTestId="currency-picker"
            onOptionClick={(option) => {
              currencyPickerProps.onCurrencyChange(option.value);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default CurrencyPicker;
